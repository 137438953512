import "../../assets/css/membership_index_index.css";
function Membership() {
  return (
    <div>
      <script>
        window.cookiesConfig = window.cookiesConfig || {};
        window.cookiesConfig.secure = false;
      </script>

      <div className="membership-index-index">
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="css/css" rel="stylesheet" />
        <div className="pageWrapper">
          <section id="text-section">
            <div className="sm-container">
              <h2
                style={{ textAlign: "center" }}
                className="content-heading content-main-heading"
              >
                Welcome to your SECUREMobile™ Network Membership
              </h2>
              <div className="content-center-img">
                <img className="iphone-img" src="img/m-screen-1.png"></img>
              </div>
              <p>
                Joining the SECUREMobile™ Network enables members to purchase
                easily, quickly and securely on their mobile device.Our trusted
                network of authorised vendors know that you want to take up
                offers and buy stuff on your phone without the complexity and
                frustration of endless forms to fill in, details to provide and
                remember.
              </p>
              <p>
                We’ve all received a great offer in an email, through a message
                or online and been ready to buy with mobile in hand, only to
                lose that “passion to purchase” courtesy of checkout processes
                that suck all the fun and excitement out of the experience.It’s
                a common experience for consumers and businesses -{" "}
                <b>
                  buying and selling on mobiles has been just too hard…..until
                  now!
                </b>
              </p>

              <h3 className="content-heading content-mid-heading">
                E-commerce CHECKOUTS don’t suit mobile small screen AND DATA
                ENTRY LIMITATIONS.
              </h3>
              <p>
                Buyers typically need to complete an average of 23 separate
                fields in order to make a purchase on mobile.Our excitement to
                purchase falls off when our frustrations take over, with too
                many screens to fill in, usernames and passwords to remember,
                email verification processes and dreaded password and account
                resets.It all becomes too hard and explains why up to 80% of
                shopping carts are abandoned with customers walking away from
                great deals and offers.
              </p>

              <h3 className="content-heading content-mid-heading">
                SECUREMobile™ is THE ANSWER to mobile checkout frustration
              </h3>
              <p>
                Our experience sending over 20 million visual and text messages
                on behalf of our clients suggests that consumers who receive
                promotions on the mobile phone seek to take up that offer on
                their phone.They want it fast, frictionless, and personal and
                SECUREMobile™ cuts through the clutter of complex Ecommerce
                processes empowering members to “click2buy” and take up offers
                including checkout and transaction in under 15 seconds (*after
                first time setup).
              </p>

              <h3 className="content-heading content-mid-heading">
                Membership Benefits
              </h3>
              <p>
                As a valued SECUREMobile™ Member you will enjoy exclusive
                benefits like:{" "}
              </p>
              <ul className="thumbs-up">
                <li>
                  <div>
                    When you receive an offer from an authorised SECUREMobile
                    Vendor, you will be able to make your decision and process
                    the purchase in under 15 seconds
                    <div></div>
                  </div>
                </li>
                <li>
                  <div>
                    Exclusive access to a trusted network of authorised vendors
                    offering their hottest deals to their most valued customers
                    <div></div>
                  </div>
                </li>
                <li>
                  <div>
                    Members can set preferences about the types of vendors and
                    products they want to see and have access to
                    <div></div>
                  </div>
                </li>
                <li>
                  <div>
                    Gifts and rewards on your birthday and the anniversary of
                    your membership
                    <div></div>
                  </div>
                </li>
                <li>
                  <div>
                    It’s free to join, and your membership is linked securely to
                    you and your mobile number
                    <div></div>
                  </div>
                </li>
              </ul>
              <p className="content-heading content-mid-heading">
                Exclusive access to a trusted network of authorised vendors
                offering their hottest deals to their most valued customers
              </p>

              <div className="content-center-img">
                <img src="img/authorised-vendor-man.png"></img>
              </div>

              <h3 className="content-heading content-mid-heading">
                Membership Pass
              </h3>
              <p>
                SECUREMobile Membership Passes cost nothing and can be
                downloaded to your phones digital wallet by simply clicking “add
                to wallet”.
              </p>
              <p>
                Membership passes identify your membership level and joining
                date as well as providing key information and links to the
                SECUREMobile Marketplace, customer service and support.Plus,
                MobileDigital can push messages about the latest deals and
                offers direct to the pass along with Birthday and Membership
                anniversary rewards.
              </p>

              <div>
                <div className="content-col">
                  <div className="content-right">
                    <img
                      className="iphone-img"
                      src="img/m-screen-2-1.png"
                    ></img>
                  </div>
                </div>
                <div className="content-col">
                  <div className="content-left">
                    <img
                      className="iphone-img"
                      src="img/m-screen-2-2.png"
                    ></img>
                  </div>
                </div>
              </div>

              <h3 className="content-heading content-mid-heading">
                Loyalty Rewards
              </h3>
              <p>
                You don’t have to do anything to get rewarded with
                SECUREMobile.Every purchase you make from an authorised
                SECUREMobile Vendor, as well as any purchases you make from the
                SECUREMobile marketplace are tracked against your unique
                membership and account.The more you checkout with SECUREMobile
                the higher your membership level, the better the rewards!
              </p>

              <div>
                <div className="content-col">
                  <div className="content-right">
                    <img className="iphone-img" src="img/m-screen-3-1.png" />
                  </div>
                </div>
                <div className="content-col">
                  <div className="content-left">
                    <img className="iphone-img" src="img/m-screen-3-2.png" />
                  </div>
                </div>
              </div>

              <h3 className="content-heading content-mid-heading">
                How does SECUREMobile™ work?
              </h3>
              <p>
                SECUREMobile™ lets companies offer a simple, fast and secure way
                for their customers to purchase products on their mobile phone
                without having to invest in adapting their existing systems to
                mobile, or building an expensive mobile APP.
              </p>
              <p>
                SECUREMobile™ is a complete re-design of the 35-year-old
                eCommerce logon process of email, remembering passwords and
                usernames.The platform recognises and works with the security
                measures embedded in your phone.It relies on the unique mobile
                number of the receiver and recognises that the phone has been
                signed into using all or some unique-to-mobile security measures
                like face recognition, fingerprint, password or access code.
              </p>
              <p>
                The first time a consumer is sent a promotion and seeks to
                purchase, they are invited to join the SECUREMobile Network as a
                member and asked to complete some personal details (ie payment
                and delivery information) many of which are automatically
                populated from information already stored within the phone in
                apps like Google or Apple Pay.
              </p>
              <p>
                The Shipping and Billing information is securely stored on
                SECUREMobile’s PCI compliant servers and is only shared if an
                order is placed.If a customer wants to take up an offer sent to
                their mobile, they simply click the active plain language link
                in the SMS or MMS promotion.Every link is a unique secure
                identifier for each individual and contains a Click2Buy.link in
                your mobile inbox.
              </p>
              <p>
                SECUREMobile™ makes buying and selling on mobile faster, more
                personalised and a more enjoyable experience thanks to the power
                and convenience of the mobile phone.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Membership;
