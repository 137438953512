import "../../assets/css/organisations_index_index.css";
function Organisation() {
  return (
    <div className="organisations-index-index">
      <div
        id="general-loader"
        data-role="checkout-loader"
        className="loading-mask"
      >
        <div className="loader">
          <img
            src="img/loader-1.gif"
            alt="Loading..."
            style={{ position: "absolute" }}
          />
        </div>
      </div>
      <div id="general-mask"></div>
      <script>
        window.cookiesConfig = window.cookiesConfig || {};
        window.cookiesConfig.secure = false;
      </script>

      <div className="page-wrapper">
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="css/css" rel="stylesheet" />
        <div className="pageWrapper">
          <section id="text-section">
            <div className="sm-container">
              <h2 className="content-heading content-main-heading">
                Introduction:{" "}
              </h2>
              <p>
                MobileDigital™ have focussed exclusively on direct to mobile
                engagement since 2012. The Founders of the company have always
                believed in the inevitability of the mobile device becoming the
                best channel of communication between organisations and
                consumers.
              </p>
              <p>
                We have been working exclusively with our client’s mobile
                customers for more than 7 years, always believing the best
                approach is to make doing things on mobile simple, fast and
                secure and that with less friction there is better traction and
                conversion.
              </p>
              <p>
                SECUREMobile™ has been developed in response to what we have
                observed firsthand with Australian customers and what we have
                seen in the UK and USA markets.In Australia we have observed
                close to 20 million visual and text messages sent by our clients
                presenting a number of phenomenon’s.
              </p>
              <ul className="thumbs-up">
                <li>
                  <div>
                    The power of 2 Way Communication, increases in clients
                    responding to promotions by text message, placing orders,
                    asking for information, or asking the supplier to hold stock
                  </div>
                </li>
                <li>
                  <div>
                    Less or Zero opt outs demonstrating their client base prefer
                    text engagement above email
                  </div>
                </li>
                <li>
                  <div>
                    Up to 40% sales conversions from personalised visual
                    promotions to VIP clients, and 5% to 20% for visual and text
                    promotions to inactive clients and new prospective
                    clients.With examples in the US of businesses receiving 90%
                    of their orders through mobile this demonstrates that there
                    is group of consumers interested in doing things quickly,
                    using their phone for convenience
                  </div>
                </li>
              </ul>
              <p>
                We proposed that if we made purchasing easy on the mobile,
                conversion on the mobile device would increase.Too many screens,
                usernames, email addresses, passwords and logon processes were
                holding back mobile users purchasing.
              </p>
              <div className="content-center-img">
                <img src="img/average-conversion.png" />
              </div>
              <h4 className="content-heading content-minor-heading">
                App like ease of use and one click checkout
              </h4>
              <p>
                SECUREMobile™ has been designed from the ground up as part of a
                mobile centric promotional, engagement and sales offering
                platform for retailers.SECUREMobile lets retailers offer a
                simple, fast and secure on-mobile buying experience without
                having to invest in the cost of building, marketing and
                maintaining a mobile App across Apple and Android ecosystems.
              </p>

              <h4 className="content-heading content-minor-heading">
                The rise of accelerated checkouts
              </h4>
              <p>
                Clever retailers and suppliers have recognised that online
                purchasers prefer easier to use accelerated checkouts.Advances
                by Shopify with ShopPay, and Stripe’s investments in a company
                called Fast, point to market recognition that consumers are
                seeking simpler and faster ways to purchase online.
              </p>
              <p>
                Our unique offering is that SECUREMobile™ is aimed at serving
                the mobile client and has the opportunity to throw open a new
                channel of purchasing convenience not constrained by a 35 year
                old eCommerce security processes.
              </p>
              <h3 className="content-heading content-mid-heading">
                BUYER BEHAVIOUR
              </h3>
              <h4 className="content-heading content-minor-heading">
                Consumers prefer to purchase on their phone.
              </h4>
              <p>
                The stats on mobile purchasing vs desktop and purchasing on
                mobile APPs indicate very strongly that consumers prefer to
                purchase on mobile – when it is easy.
              </p>
              <p>
                When a retailer has a mobile friendly APP, they can expect
                around 77% of purchases to occur on the mobile and only 33% on
                desktop.
              </p>
              <p>
                Even though consumers spend almost double the amount of time on
                mobile than they do on desktop, without an APP, desktop
                purchases represent 65% of conversions and published sales
                conversion rates on mobile are less than 1.5%, with 4.2%
                choosing to convert on desktop (BigCommerce 2020).
              </p>

              <h4 className="content-heading content-minor-heading">
                Promote on Mobile, Convert on Mobile
              </h4>
              <p>
                There is no doubt that text (using SMS) and visual marketing
                (using MMS) delivers superior stakeholder engagement and sales
                results.Over the years our clients have produced outstanding
                case studies that illustrate client engagement levels, and sales
                conversion rates achieved with personalised, and targeted SMS
                and MMS sales campaigns.
              </p>

              <h4 className="content-heading content-minor-heading">
                But we knew we could do more
              </h4>
              <p>
                When we talked to our clients, a lot of the feedback included
                the frustration experienced when the offer was difficult to
                complete on their mobile.
              </p>
              <p>
                We’ve all had the experience, we get emailed, SMS’s or MMS’s
                with a great offer, and think - yep I’m buying.Regrettably, and
                too often, by the time we have remembered our password, or gone
                through the password reset, finally logged on, and then had to
                complete checkout, delivery and payment screens, our enthusiasm
                for purchase has gone, frustration sets in and we do not
                complete the purchase.
              </p>
              <p>
                The feedback we received from clients and their customers was,
                if you send me a promotion to my phone, and I like that
                promotion and want to buy it, then I should be able to just
                click a link on my phone and my purchase is instantly and
                securely completed and the goods should be on their way to me.
              </p>
              <p>
                We observed an amazing shift in the last 18 months, when clients
                were sending out pictures of products they were seeking to sell,
                and people who received the promotion were texting back (and
                they had not been asked to) saying – 'reserve me 3', 'yes I’ll
                have two', 'I am on holidays but will place an order next week'.
              </p>
              <p>
                One customer who sent almost 6,000 promotions received over 800
                text messages from clients within 30 minutes!!
              </p>
              <p>
                We saw this as a huge opportunity, we investigated trends in the
                UK and the USA where we saw companies like Dirty Lemon taking
                over 90% of their orders via text.There were others that
                indicated that consumers want to act quickly on their phones,
                and traditional ecommerce checkouts were not fast or easy enough
                for the mobile first consumer.
              </p>
              <p>
                We knew then that there was room in the market for a non app,
                fast checkout for mobile – and that idea became the
                SECUREMobile™ Network.
              </p>

              <h4 className="content-heading content-minor-heading">
                On-Mobile v Online
              </h4>
              <p>
                SECUREMobile™ recognises the difference between going online on
                a desktop, laptop or iPad and being on-mobile.
              </p>
              <p>
                Our mobile devices are devices we use to get stuff done, and it
                is annoying when we want to get something done on our phone and
                we can’t because the screens are not friendly and are not
                designed for mobile.We have all experienced this frustration.So,
                we created an alternative.
              </p>
              <p>
                If you are a registered member of the SECUREMobile™ Network, you
                will be able to complete any purchase from a SECUREMobile™
                Authorised Vendor, a SECUREMobile™ Promotion or from the
                SECUREMobile™ Marketplace in under 30 seconds.
              </p>

              <h3 className="content-heading content-mid-heading">
                HOW IT WORKS
              </h3>
              <h4 className="content-heading content-minor-heading">
                How does SECUREMobile™ Work
              </h4>

              <div className="content-center-img">
                <img src="img/checkout-screen.png" />
              </div>

              <p>
                SECUREMobile™ lets companies offer a simple, fast and secure way
                for their customers to purchase products on their mobile without
                having to invest in adapting their existing systems to mobile,
                or building an expensive mobile APP.
              </p>
              <p>
                SECUREMobile™ is a complete re-design of the 35-year-old
                eCommerce logon process of email, remembering passwords and
                usernames.The platform recognises and works with the security
                measures included on your phone.It relies on the unique mobile
                number and recognises that the phone has been signed into using
                all or some unique-to-mobile security measures like face
                recognition, fingerprint, password or access code.
              </p>
              <p>
                The first time a consumer is sent a promotion and seeks to
                purchase, the consumer is invited to join the SECUREMobile™
                Network as a member, by checking the information and entering
                their delivery and payment details.
              </p>
              <p>
                The software lets customers save their mobile, email address,
                credit card, and shipping and billing information so they can
                complete their transaction faster the next time they are sent a
                promotion by a SECUREMobile™ authorised vendor.
              </p>
              <p>
                The Shipping and Billing information is securely stored on
                SECUREMobile’s secure encrypted servers and is only shared if an
                order is placed.If a customer wants to take up an offer sent to
                their mobile, they simply click the active plain language link
                in the SMS or MMS promotion.Every link is a unique secure
                identifier for each receiver and contains a Click2Buy.link in
                your mobile inbox.
              </p>
              <p>
                Once the Click2Buy.link is clicked the purchaser has 3 minutes
                to confirm the purchase details, delivery and payment
                information and complete the transaction.
              </p>
              <p>
                When you choose to go direct to SECUREMobile™.Net to make an
                on-mobile device purchase, you can save your customers time when
                they check out.
              </p>
              <p>
                If your customers have questions about how SECUREMobile™ works,
                or how their data is stored, then you can send them to{" "}
                <a href="https://www.securemobile.net/membership">
                  www.securemobile.net/membership
                </a>
                for complete peace of mind.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
export default Organisation;
