


import { Route,Switch,BrowserRouter as Router,Link } from "react-router-dom";
import HomePage from './components/pages/HomePage';
import ContactUs from './components/pages/ContactUs';
import Membership from './components/pages/Membership';
import Organisation from './components/pages/Organisation';
import Privacy from './components/pages/Privacy';
import Security from './components/pages/Security';
import Terms from './components/pages/Terms';
import Header from './components/pages/Header';
import Footer from './components/pages/Footer';

function App() {
  return (
    <div className="App">
      <Router>
        <div>   
        
           <div className="container">
           <Header />
            <Switch>
              <Route path="/" exact component={HomePage} /> 
              <Route path="/contactus" exact component={ContactUs} /> 
              <Route path="/membership" exact component={Membership} />
              <Route path="/organisation" exact component={Organisation} />
              <Route path="/privacy" exact component={Privacy} /> 
              <Route path="/security" exact component={Security} />
              <Route path="/terms" exact component={Terms} />
              
              
            </Switch>
            <Footer />
            
          </div>
          
        </div>
      </Router>
    </div>
  );
}

export default App;
