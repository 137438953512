import "../../assets/css/cms_index_index.css";
import Footer from "./Footer";

function HomePage() {
  return (
    <div className="cms-index-index">
      <div
        id="general-loader"
        data-role="checkout-loader"
        className="loading-mask"
      >
        <div className="loader">
          <img
            src="img/loader-1.gif"
            alt="Loading..."
            style={{ position: "absolute" }}
          />
        </div>
      </div>
      <div id="general-mask"></div>

      <script>
        window.cookiesConfig = window.cookiesConfig || {};
        window.cookiesConfig.secure = false;
      </script>

      <div className="page-wrapper">
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="css/css" rel="stylesheet" />
        <div className="pageWrapper">
          <section className="sections banner" id="top-section">
            <div id="gradient-top-section"></div>
            <video
              id="playing-top-section"
              autoplay="true"
              muted=""
              playsinline=""
              loop="true"
              src="videos/SecureMobileWebsite.mp4"
              type="video/mp4"
            ></video>
            <div className="sm-container" id="text-top-section">
              <div>We Have Better Things</div>
              <div>To Do Than</div>
              <div>Wrestling With</div>
              <div
                style={{ backgroundColor: "#30b7c6", display: "inline-block" }}
              >
                Checkouts
              </div>
              <div>On Our Mobile Devices</div>
            </div>
          </section>
          <section className="sections banner" id="video-section">
            <div className="sm-container">
              <div id="boxes-section-heading">
                <div>Fast, Simple and Secure</div>
                <div>&nbsp;</div>
              </div>
              <p>
                Finally, the one click mobile checkout that retailers and
                shoppers have been waiting for
              </p>
              <video
                style={{ width: "100%" }}
                controls="true"
                src="videos/SecureMobile_Visionary.mp4"
                type="video/mp4"
              ></video>
            </div>
          </section>
          <section className="sections" id="boxes-section">
            <div className="boxes-container">
              <div id="boxes-section-heading">
                <div>
                  SECUREMobile™ represents a significant shift in mobile
                  eCommerce practices for both consumers and retailers.
                </div>
              </div>
              <a className="box">
                <img src="img/box_1_New_Innovation.png"></img>
                <h3>REVOLUTIONISING MOBILE COMMERCE</h3>
                <p>
                  SECUREMobile™ significantly reduces the inputs and time it
                  takes to purchase securely on mobile…
                </p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    SECUREMobile has completely redesigned the eCommerce
                    checkout process by removing the things that slow us down
                    when we are trying to make a purchase on our mobile.
                  </p>
                  <p>
                    No more forms to fill in for each purchase, apps to download
                    or details to remember, SECUREMobile significantly reduces
                    the required number of screens, inputs and time it takes to
                    purchase securely on mobile.
                  </p>
                  <p>
                    When you are sent a SECUREMobile offer, the vendor has
                    approved you for purchase, the Click2Buy link is unique to
                    every customer, and security is supported by your unique
                    mobile phone number and your unique phone ID and security
                    providing 2factors of authentication.
                  </p>
                </div>
              </a>

              <a className="box">
                <img src="img/box_2_6_Built_specifically_for_mobile.png"></img>
                <h3>REDESIGNED &amp; REBUILT FOR MOBILE</h3>
                <p>
                  We've turned Ecommerce into Mcommerce to leverage the power
                  and convenience of the mobile phone...
                </p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    Reducing an average of 23 screens of inputs for eCommerce to
                    2 screens and a super-fast and secure checkout was never
                    going to be easy.{" "}
                  </p>
                  <p>
                    We observed there was a huge gap between the number of
                    purchases made on desktop and the significantly lower number
                    of purchases made on mobile devices, despite the mobile
                    phone being the most commonly used device for browsing.
                  </p>
                  <p>
                    So we turned eCommerce into “mCommerce” and turned it on its
                    head.{" "}
                  </p>
                  <p>
                    We redesigned and rebuilt the model encouraging vendors to
                    get smart about the way they communicate with their clients,
                    focussing on better understanding them through their data
                    and shaping their offers and experiences for each
                    individual.
                  </p>
                  <p>
                    Instead of you going to the store, the store comes to you.
                  </p>
                </div>
              </a>

              <a className="box">
                <img src="img/box_3_Approved_Vendors.png"></img>
                <h3>APPROVED VENDOR PROCESS</h3>
                <p>
                  Only vendors who meet the highest standards of security and
                  compliance within GDPR requirements...
                </p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    With great power comes great responsibility and we take the
                    power of personalised business messaging very seriously.{" "}
                  </p>
                  <p>
                    {" "}
                    That’s why only suppliers who conform to the highest
                    standards of security and compliance within GDPR data
                    protection requirements and commit to strict adherence to
                    the government’s anti-SPAM legislation (SPAM ACT 1973)
                    regarding unsolicited electronic communications are
                    authorised as SECUREMobile.net vendors.
                  </p>
                </div>
              </a>

              <a className="box">
                <img src="img/box_4_New_fast.png"></img>
                <h3>SUPER FAST CHECKOUT ON MOBILE</h3>
                <p>
                  A super-fast, super-secure and super-convenient accelerated
                  checkout built for mobile…
                </p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    Once registered as a SECUREMobile client (this happens the
                    first time you use the SECUREMobile Network) it should take
                    a user between 10 and 15 seconds to take up an offer and
                    complete the purchase. No, not a miss-print - 10 to 15
                    seconds!!!!
                  </p>
                  <p>
                    SECUREMobile is an accelerated checkout that lets customers
                    save their email address, credit card, shipping and billing
                    information so they can complete their transaction faster.
                    Payment information can even be added across from cards
                    stored in the phone for Apple and Google Pay eliminating
                    friction and fumbling that often results in abandoned
                    shopping carts in online stores.
                  </p>
                  <p>
                    The customer’s shipping and billing information is securely
                    stored on SECUREMobile’s PCI compliant server and only
                    synced at purchase with the vendors securelink helping to
                    make the process super-fast, super-secure and
                    super-convenient!!
                  </p>
                </div>
              </a>

              <a className="box">
                <img src="img/box_6_Trusted_Network.png"></img>
                <h3>A SECURE NETWORK</h3>
                <p>
                  Quality assured authorised vendors and PCI / GDPR compliant
                  data security for peace of mind…
                </p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    When you register in the SECUREMobile network you will have
                    access to hundreds of authorised Quality Assured retailers.{" "}
                  </p>
                  <p>
                    Your personal secure data is managed within the SECUREMobile
                    network, payments are managed through the Stripe platform
                    and no records of your payment information are recorded .
                  </p>
                  <p>
                    {" "}
                    All data transfers are secured in PCI compliant processes to
                    protect all data in line with global GDPR requirements.
                  </p>
                </div>
              </a>

              <a className="box ">
                <img src="img/box_7_Take_Control.png"></img>
                <h3>TAKE CONTROL OF MOBILE CLIENT ENGAGEMENT</h3>
                <p>
                  For retailers the “new normal” and the “next normal” when
                  Covid19 settles down will be a long way from the retail model…
                </p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    For retailers, the “new normal” and the “next normal” when
                    Covi19 settles down will be a long way from the retail model
                    we are all used to. Online is growing like never before,
                    physical retail is struggling, and big brands are pushing
                    online with many companies reporting that online has
                    accelerated between 3 and 5 years’ worth of growth in the
                    first 6 months of 2020.{" "}
                  </p>
                  <p>
                    Customers in 2020 want a personalised experience offering
                    them something special. Brands and businesses in 2020 want
                    foot traffic in front of their offers and “the new normal”
                    means that foot traffic needs to be in the digital space.
                    SECUREMobile™ combined with intouch™ patented technology
                    gives businesses the power to deliver direct, rich,
                    multimedia marketing and sales offers right to their
                    customers’, staff or suppliers’ mobile phones.
                  </p>
                  <p>
                    We do know mobile and text is the preferred engagement
                    platform that almost 90% of consumers prefer. The future of
                    successful client management should not be outsourced, use
                    the data you have collected, try stuff, explore new ideas
                    and meet your clients where they want to be met – on their
                    mobile.{" "}
                  </p>
                </div>
              </a>

              <a className="box">
                <img src="img/box_9_Image_3.png"></img>
                <h3>BETTER TARGETING WITH DATA</h3>
                <p>
                  Manage, measure and grow your data for better conversion and
                  marketing ROI….
                </p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    The processes of pushing offers out by email to your whole
                    data base are long gone. CRM systems are able to target
                    client data bases and use client history to predict and
                    recommend likely buying behaviours.{" "}
                  </p>
                  <p>
                    Sure email is low cost, and it can generate revenues, and
                    when you stop it the revenue goes away. BUT… is it the best
                    way to engage and build a relationship of value with your
                    clients?{" "}
                  </p>
                  <p>
                    Are you treating all your clients as individuals? Are you
                    using the data you have to deliver targeted promotions
                    direct to your client’s hand that are personal, valuable,
                    contextual and authentic?{" "}
                  </p>
                  <p>
                    If your current CRM can find you 200 clients that are likely
                    to purchase a product, then why not push the offer just to
                    those 200 people? Why not shift from offers to large groups
                    to lots of different campaigns to lots of smaller groups.
                    Better data, better segmentation, better offers, better ROI.
                  </p>
                  <p>
                    When sending a message directly to your customers phone,
                    your offer should be personal, include a time and scarcity
                    motivator and a value offering. SECUREMobile gives the
                    receiver the instant ability to take up that offer and
                    complete the transaction, all on the device it was delivered
                    to in under 15 seconds.{" "}
                  </p>
                </div>
              </a>

              <a className="box">
                <img src="img/box_10_Image_2 (1).png" />
                <h3>THE RETAILER COMES TO YOU</h3>
                <p>
                  {" "}
                  What if you could message your customers a special deal today
                  and have them “click-to-buy”?
                </p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    What if you could setup your own online store just by
                    messaging your customers something special?
                  </p>
                  <p>
                    All you need to do all of this yourself is the easy to use
                    Secure Mobile platform with intouch and the phone numbers of
                    your favourite customers.
                  </p>
                  <p>
                    What if you could message your customers a special deal
                    today and have them “click-to-buy”?
                  </p>
                  <p>
                    Your initiative, your idea, your hard work and your business
                    deserve the attention of your customers so take back control
                    of your contact with them and send something personalised
                    straight to their phone today.
                  </p>
                </div>
              </a>

              <a className="box">
                <img
                  src="img/intouch-logo.svg"
                  style={{ marginBottom: 20 }}
                ></img>
                <h3 style={{ fontColor: "red" }}>intouch</h3>
                <p>The mobile marketing platform powering SECUREMobile</p>
                <h4>
                  Read More <i className="fa fa-caret-right"></i>
                </h4>
                <div className="more-info">
                  <p>
                    <b>
                      intouch is a Software as a Service Platform (SaaS) that
                      offers organisations better access to their mobile clients
                      by enabling mobile clients to do more on their mobile
                      devices.
                    </b>
                  </p>
                  How does SECUREMobile™ work?
                  <p>
                    intouch was released in 2018, intouch users have sent close
                    to 20 million personalised visual and text messages direct
                    to mobile text inboxes.
                  </p>
                  <p>
                    intouch enables organisations to self manage and take
                    control of all aspects of their mobile communications with
                    their clients, offering 1:1 personalisation to the mobile
                    number level, with 2WAY messaging, promotion tools,
                    Add2Wallet function, list management and loyalty.
                  </p>
                </div>
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
