import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  let location = useLocation();
  console.log(location);
  let pathName = location.pathname;
  return (
    <section className="sections headers animate">
      <header className="header animate">
        <div className="wrapper">
          <div className="hdr-logo" role="banner">
            <a className="hdr-logo-link" href="/" rel="home">
              <img
                src="img/SecureMobile_Logos_white-01.png"
                alt="SECUREMobile™"
              />
            </a>
          </div>

          <div className="main-menu animate">
            <nav id="nav-main" className="nav-main" role="navigation">
              <ul className="nav-primary">
                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-239 menu-item-has-children  dropdown-hover">
                  <Link
                    className={`nav_link animate ${
                      pathName === "/" ? "header-active" : ""
                    }`}
                    target=""
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <div className="header-line">|</div>
                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-427 ">
                  <Link
                    className={`nav_link animate ${
                      pathName === "/organisation" ? "header-active" : ""
                    }`}
                    target=""
                    to="/organisation"
                  >
                    Organisations
                  </Link>
                </li>
                <div className="header-line">|</div>
                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-239 menu-item-has-children  dropdown-hover">
                  <Link
                    className={`nav_link animate ${
                      pathName === "/membership" ? "header-active" : ""
                    } `}
                    target=""
                    to="/membership"
                  >
                    Membership
                  </Link>
                </li>
                <div className="header-line">|</div>
                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-239">
                  <Link
                    className={`nav_link animate ${
                      pathName === "/security" ? "header-active" : ""
                    }`}
                    target=""
                    to="/security"
                  >
                    Data Security
                  </Link>
                </li>
                <div className="header-line">|</div>
                <li className=" menu-item menu-item-type-post_type menu-item-object-page menu-item-239">
                  <a
                    className={`nav_link animate `}
                    href="https://intouch.digital/"
                    target="_blank"
                  >
                    inTouch™
                  </a>
                </li>
                <li className="gray_btn menu-item menu-item-type-custom menu-item-object-custom menu-item-240 ">
                  <Link
                    id="demoButton"
                    className={`nav_link animate `}
                    to="/contactus"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="blue_btn menu-item menu-item-type-post_type menu-item-object-page menu-item-239 menu-item-has-children dropdown-hover login-btn">
                  <a
                    className={`nav_link animate `}
                    id="header-register"
                    href="https://www.tap2buy.com/"
                    target="_blank"
                  >
                    Register
                  </a>
                </li>
              </ul>
            </nav>
            <div id="hamburger-nav-menu-x">X</div>
            <button className="c-hamburger c-hamburger--htx">
              <span>toggle menu</span>
            </button>
          </div>
        </div>
      </header>
    </section>
  );
};
export default Header;
