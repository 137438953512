import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <section className="sections" id="bottom-banner-section">
        <img id="bottom-banner-path" src="img/Path_39.png"></img>
        <img id="bottom-banner-ellipse" src="img/Ellipse_1.png"></img>
        <div id="bottom-banner-gradient"></div>
        <div className="sm-container" id="bottom-banner-container">
          <img
            id="bottom-banner-trolley"
            src="img/11_Purchase_on_mobile.png"
          ></img>
          <div id="bottom-banner-text-1">
            <span>
              Finally, the one click mobile checkout we've all been waiting for.
            </span>
          </div>
          <div id="bottom-banner-text-2">FAST, SIMPLE &amp; SECURE</div>
        </div>
      </section>
      <section className="sections" id="top-footer-section">
        <div className="sm-container">
          <div className="top-footer-col" id="top-footer-col-0"></div>
          <div className="top-footer-col" id="top-footer-col-1">
            <a href="https://mobile.digital/">
              <img
                className="footer-logo-small"
                id="footer-sm-logo"
                src="img/SecureMobile_Logos_black-02.png"
              />
            </a>
          </div>
          <div className="top-footer-col" id="top-footer-col-2">
            <div id="top-footer-text-1" className="top-footer-text">
              <div>
                <img className="country-icon" src="img/aus.png"></img>
                <a href="tel:1800443848">
                  <span>Call</span> 1800 443 848
                </a>
              </div>
              <div>
                <img className="country-icon" src="img/aus.png"></img>
                <a href="sms:+61428094360?&amp;body=INFO">
                  Text <span>Info</span> to +61 428 094 360
                </a>
              </div>
              <div>
                <img className="country-icon" src="img/uk.png"></img>
                <a href="sms:+447537416864?&amp;body=INFO">
                  Text <span>Info</span> to +44 7537 416864
                </a>
              </div>
            </div>

            <div id="middle-line"></div>
            <div id="top-footer-text-2" className="top-footer-text">
              <div id="legal-heading">Legal</div>
              <div className="footer-link">
                <a href="/terms">Terms &amp; Conditions</a>
              </div>
              <div className="footer-link">
                <a href="/privacy">Privacy Policy</a>
              </div>
              <div className="footer-link">
                <a href="/privacy">Cookie Policy</a>
              </div>
            </div>
          </div>
          <div className="top-footer-col" id="top-footer-col-22">
            <a
              href="https://www.linkedin.com/company/mobiledigital/about"
              target="_blank"
            >
              <img className="footer-logo" src="img/securemobile3.jpg" />
            </a>
            <a
              href="https://www.facebook.com/MobileDigitalAus/"
              target="_balnk"
            >
              <img className="footer-logo" src="img/securemobile4.jpg" />
            </a>
          </div>

          <div className="top-footer-col" id="top-footer-col-3">
            <img className="footer-logo" src="img/DMA.png"></img>
            <img className="footer-logo" src="img/Authorised_vendor.png"></img>
          </div>

          <div
            className="top-footer-col"
            id="top-footer-col-4"
            style={{ height: 115, position: "relative" }}
          >
            {/* <div id="last-line"></div> */}
            <a
              href="https://intouch.digital/"
              target="_blank"
              style={{
                height: 50,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <img src="img/intouch-logo.svg" style={{ top: 0 }} />
            </a>
          </div>
        </div>
      </section>
      <section className="sections" id="bottom-footer-section">
        <div className="sm-container">
          <div id="footer-copyright">
            Copyright <span id="footer-year">2021</span> | MobileDigital
            Marketing Pty Ltd . ABN 55 614 170 357 | MobileDigital Marketing Ltd
            . CRN: 10014105
          </div>
        </div>
      </section>
    </div>
  );
};
export default Footer;
