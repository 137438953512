import "../../assets/css/security_index_index.css";
function Security() {
  return (
    <div>
      <script>
        window.cookiesConfig = window.cookiesConfig || {};
        window.cookiesConfig.secure = false;
      </script>

      <div className="security-index-index">
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link href="css/css" rel="stylesheet" />
        <div className="pageWrapper">
          <section id="text-section">
            <div className="sm-container">
              <h2 className="content-heading content-main-heading">
                Protecting customer data and privacy
              </h2>
              <p>
                MobileDigital is GDPR (General Data Protection Regulations)
                compliant. All processes and software applications have been
                independently assessed and accredited as compliant by DMA
                (Direct Marketing Association, UK)
              </p>
              <p>
                GDPR directs that your personal data does not live or is not
                processed outside the country of origin, and your data is stored
                securely and not shared or used without your consent. Data
                processes also have provisions in place to be removed upon
                request.
              </p>
              <p>
                Australia has not legislated to implement GDPR compliance but
                MobileDigital has adopted the GDPR rules and regulations and
                implements GDPR practices with data stored in its applications
                in Australia.
              </p>
              <p>
                MobileDigital has a dedicated DPO (Data Protection Officer) and
                abides by the following privacy directives
              </p>
              <ul>
                <li>PECR (Privacy and Electronic Communications</li>
                <li>(EU Directive) Regulations 2003),</li>
                <li>EU’s GDPR (General Data Protection Regulation)</li>
                <li>
                  ePR (Regulation on Privacy and Electronic Communications)
                </li>
              </ul>
              <p>
                MobileDigital also performs continuous quality control to ensure
                clients on intouch are adhering to privacy and data usage best
                practices. The company reserves the right to refuse to work with
                companies that fail to comply with legislative requirements.
              </p>

              <h3 className="content-heading content-mid-heading">
                What is GDPR ?
              </h3>
              <p>
                <b>Adapted from an article written by Michael Nadeau</b>
              </p>
              <p>Senior Editor, CSO | 12 JUNE 2020 20:00 AEST</p>

              <p>
                Companies that collect data on citizens in European Union (EU)
                countries an in the UK need to comply with strict new rules
                around protecting customer data. The General Data Protection
                Regulation (GDPR) sets a new standard for consumer rights
                regarding their data, and companies are required to put systems
                and processes in place to maintain compliance.
              </p>
              <p>
                Compliance sets new expectations of security teams. For example,
                the GDPR takes a wide view of what constitutes personal
                identification information. Companies need the same level of
                protection for things like an individual’s IP address or cookie
                data as they do for name, address and Social Security number.
              </p>
              <p>
                MobileDigital adopted and was audited for GDPR as we have
                clients in the UK, and further because the standards in
                Australia are not well defined. GDPR represents the closest
                thing the world has to a standard set of requirements for the
                protection of data and user information.
              </p>
              <p>
                The European Parliament adopted the GDPR in April 2016,
                replacing an outdated data protection directive from 1995. It
                carries provisions that require businesses to protect the
                personal data and privacy of EU citizens for transactions that
                occur within EU member states. The GDPR also regulates the
                exportation of personal data outside the EU.
              </p>
              <p>
                The provisions are consistent across all 28 EU member states,
                which means that companies have just one standard to meet within
                the EU. However, that standard is high and requires significant
                investment to meet requirements and to administer.
              </p>

              <h3 className="content-heading content-mid-heading">
                Why does the GDPR exist?
              </h3>
              <p>
                The short answer to that question is public concern over
                privacy. Europe in general has long had more stringent rules
                around how companies use the personal data of its citizens. The
                GDPR replaces the EU’s Data Protection Directive, which went
                into effect in 1995. This was well before the internet became
                the online business hub that it is today. Consequently, the
                directive is outdated and does not address many ways in which
                data is stored, collected and transferred today.
              </p>
              <h3 className="content-heading content-mid-heading">
                How real is public concern over privacy?
              </h3>
              <p>
                It is significant and it grows with every new high-profile data
                breach. According to the RSA Data Privacy &amp; Security Report,
                for which RSA surveyed 7,500 consumers in France, Germany,
                Italy, the UK and the U.S., 80% of consumers said lost banking
                and financial data is a top concern. Lost security information
                (e.g., passwords) and identity information (e.g., passports or
                driving license) was cited as a concern of 76% of the
                respondents.
              </p>
              <p>
                An alarming statistic for companies that deal with consumer data
                is the 62% of the respondents to the RSA report who say they
                would blame the company for their lost data in the event of a
                breach, not the hacker. The report’s authors concluded that, “As
                consumers become better informed, they expect more transparency
                and responsiveness from the stewards of their data.”
              </p>
              <p>
                Lack of trust in how companies treat their personal information
                has led some consumers to take their own countermeasures.
                According to the report, 41% of the respondents said they
                intentionally falsify data when signing up for services online.
                Security concerns, a wish to avoid unwanted marketing, or the
                risk of having their data resold were among their top concerns.
              </p>
              <p>
                The report also shows that consumers will not easily forgive a
                company once a breach exposing their personal data occurs.
                Seventy-two percent of US respondents said they would boycott a
                company that appeared to disregard the protection of their data.
                Fifty percent of all respondents said they would be more likely
                to shop at a company that could prove it takes data protection
                seriously.
              </p>
              <p>
                MobileDigital adhere to strict data protection policies and
                believe that as businesses continue their digital
                transformations, making greater use of digital assets, services,
                and big data, we must also be accountable for monitoring and
                protecting that data on a daily basis.
              </p>

              <h3 className="content-heading content-mid-heading">
                What types of privacy data does the GDPR protect?
              </h3>
              <ul>
                <li>
                  Basic identity information such as name, address and ID
                  numbers
                </li>
                <li>
                  Web data such as location, IP address, cookie data and RFID
                  tags
                </li>
                <li>Health and genetic data</li>
                <li>Biometric data</li>
                <li>Racial or ethnic data</li>
                <li>Political opinions</li>
                <li>Sexual orientation</li>
              </ul>

              <h3 className="content-heading content-mid-heading">
                Why gdpr as our standard ?
              </h3>
              <p>
                We believe that any company that stores or processes personal
                information about citizens should comply with the GDPR, even if
                not in the EU. Australian legislation and controls on data and
                privacy are not as stringent as GDPR.
              </p>
              <p>
                MobileDigital adopted GDPR as a standard. Data protection for
                the most part in Australia has guidelines established by the
                SPAM Act 2003, loosely interpreted by ACMA and a lot of self
                regulation. GDPR is becoming a global standard, we believe at
                some stage in the near future the legislation similar to the
                requirements of GDPR as they relate to data protection and
                privacy standards will be introduced into Australia.
              </p>

              <h3 className="content-heading content-mid-heading">
                Who is responsible for compliance?
              </h3>
              <p>
                The GDPR defines several roles that are responsible for ensuring
                compliance: data controller, data processor and the data
                protection officer (DPO). The data controller defines how
                personal data is processed and the purposes for which it is
                processed. The controller is also responsible for making sure
                that outside contractors comply.
              </p>
              <p>
                The GDPR requires the controller and the processor to designate
                a DPO to oversee data security strategy and GDPR compliance.
                Companies are required to have a DPO if they process or store
                large amounts of citizen data, process or store special personal
                data, regularly monitor data subjects, or are a public
                authority. Some public entities such as law enforcement may be
                exempt from the DPO requirement.
              </p>
              <p>
                MobileDigital’s Data Protection Officer is Eibhlis Stuckey, the
                Founder and CTO of MobileDigital. The GDPR holds processors
                liable for breaches or non-compliance. It’s possible, then, that
                both your company and processing partner such as a cloud
                provider will be liable for penalties even if the fault is
                entirely on the processing partner.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Security;
