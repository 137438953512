import "../../assets/css/contactus_index_index.css";
import HubspotForm from "react-hubspot-form";
function ContactUs() {
  return (
    <div className="contactus-index-index">
      <div id="general-loader" data-role="checkout-loader" class="loading-mask">
        <div class="loader">
          <img
            src="img/loader-1.gif"
            alt="Loading..."
            style={{ position: "absolute" }}
          />
        </div>
      </div>
      <div id="general-mask"></div>
      <script>
        window.cookiesConfig = window.cookiesConfig || {};
        window.cookiesConfig.secure = false;
      </script>

      <div className="hb-container">
        <h2
          style={{ textAlign: "left" }}
          className="content-heading content-main-heading"
        >
          Contact Us
        </h2>

        <HubspotForm
          portalId="8085500"
          formId="c7d1365b-0539-4ee7-b41c-17ae8e3fab6e"
          onSubmit={() => console.log("Submit!")}
          onReady={(form) => console.log("Form ready!")}
          loading={<div>Loading...</div>}
        />
      </div>

      <div class="page-wrapper">
        <div class="page-wrapper">
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <link href="css/css" rel="stylesheet" />
          <div class="pageWrapper">
            <div class="quickshop-modal"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
